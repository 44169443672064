import { useEffect, useRef } from 'react'
import type * as React from 'react'

import { Popover as LibPopover } from '@b-stock/bstock-react'

interface IProps {
  isOpen: boolean
  parentElement: HTMLElement | null
  options?: React.ComponentProps<typeof LibPopover>['options']
  setIsOpen: (prop: boolean) => void
  children: React.ReactNode
}

export const Popover = ({
  isOpen,
  parentElement,
  setIsOpen,
  options,
  children,
}: IProps) => {
  const dropdown = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    function handleClick(e: MouseEvent) {
      if (
        parentElement &&
        !parentElement.contains(e.target as Element) &&
        dropdown.current &&
        !dropdown.current.contains(e.target as Element)
      ) {
        setIsOpen(false)
      }
    }

    window.addEventListener('mousedown', handleClick)
    return () => window.removeEventListener('mousedown', handleClick)
  }, [isOpen, parentElement, setIsOpen])

  if (typeof window !== 'undefined') {
    return (
      <LibPopover
        open={isOpen}
        referenceElement={parentElement}
        options={options}
      >
        <div ref={dropdown}>{children}</div>
      </LibPopover>
    )
  }
  return null
}
