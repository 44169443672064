import type { ReactNode } from 'react'
import { useMemo, useCallback } from 'react'

import type { AuctionFiltersState } from '@components/AuctionSearchProvider'

import AppliedFilterSection from './AppliedFilterSection'
import type { RecordType as CategoricalRecordType } from './CategoricalFilter'
import type { Range } from './NumericRangeFilter'
import { IntegerFormat, MSRPFormat } from './NumericRangeFilter'
import type { AppliedProps } from '../types'

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}[keyof T]

const useFilterOptions = (
  availableFilterSet?: {
    [key: string]: number
  },
  toDisplayName?: (option: string) => ReactNode
): CategoricalRecordType[] => {
  return useMemo(() => {
    if (!availableFilterSet) {
      return []
    }

    return Object.keys(availableFilterSet)
      .sort((a, b) => availableFilterSet[b] - availableFilterSet[a])
      .map((option) => ({
        key: option,
        displayName: toDisplayName ? toDisplayName(option) : option,
      }))
  }, [availableFilterSet, toDisplayName])
}

const createRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string,
  FormatComponent: React.ComponentType<{
    lower: number | null
    upper: number | null
  }>
) => {
  const Applied = ({ filtersState, updateFilters }: AppliedProps) => {
    const range = filtersState[filterKey]

    const value = useMemo(() => {
      if (range.filter(Boolean).length === 0) {
        return null
      }

      const [lower, upper] = range

      return {
        key: filterKey,
        name: <FormatComponent lower={lower || 0} upper={upper} />,
      }
    }, [range])

    const onRemove = () => {
      updateFilters({ [filterKey]: [null, null] })
    }

    if (!value) {
      return <></>
    }

    return (
      <AppliedFilterSection
        label={label}
        values={[value]}
        onRemove={onRemove}
      />
    )
  }

  return Applied
}

const createNumericRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string
) => {
  return createRangeAppliedComponent(filterKey, label, IntegerFormat)
}

const createCurrencyRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string
) => {
  return createRangeAppliedComponent(filterKey, label, MSRPFormat)
}

const createCategoricalAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, string[]>,
  label: string,
  toDisplayName: (s: string) => React.JSX.Element
) => {
  const Applied = ({ filtersState, updateFilters }: AppliedProps) => {
    const filter = filtersState[filterKey]

    const values = useMemo(
      () =>
        filter.map((cat: string) => ({
          key: cat,
          name: toDisplayName(cat),
        })),
      [filter]
    )

    const onRemove = useCallback(
      (keyToRemove: string) => {
        const newValue = filter.filter((key: string) => {
          return keyToRemove !== key
        })
        updateFilters({ [filterKey]: newValue })
      },
      [filter, updateFilters]
    )

    return (
      <AppliedFilterSection label={label} values={values} onRemove={onRemove} />
    )
  }

  return Applied
}

const formatTitle = (title: string): string => {
  const match = title
    .replace(/filter|filters/gi, '')
    .match(/[A-Z][a-z]+|[0-9]+/g)
  if (match && match[0]) {
    return match.length > 1
      ? [match[0]].concat(match.slice(1, match.length)).join(' ').toLowerCase()
      : match[0]
  }
  return title
}

export {
  useFilterOptions,
  formatTitle,
  // presetAndFreeRangesFromMixedRanges,
  createNumericRangeAppliedComponent,
  createCurrencyRangeAppliedComponent,
  createCategoricalAppliedComponent,
}
