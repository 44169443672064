import { styled } from 'styled-components'

import { Breakpoints } from '@b-stock/bstock-react/design-system'

export const ContentContainer = styled.div`
  box-sizing: border-box;
  margin: 0 1rem;

  @media ${Breakpoints.min.medium} {
    margin: 0 2rem;
  }
  @media ${Breakpoints.min.large} {
    margin: 0 auto;
    max-width: min(76.5rem, calc(100vw - 4rem));
  }
`
