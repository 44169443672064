import reduce from 'lodash/reduce'

import { AuctionSearchSortOrder } from '@b-stock/bstock-next'

import type { Range } from '@commonTypes'
import type {
  AuctionSearchSortBy,
  SearchState,
} from '@components/BaseSearchProvider/types'
import {
  validateSortBy,
  validateSortOrder,
} from '@components/BaseSearchProvider/utils'
import {
  isDateRange,
  isRange,
  isStringList,
} from '@components/layouts/UserPageTemplate'
import type { SearchParams } from '@queries/listingSearchQuery/types'

import defaultState from './defaultState'
import type { AuctionFiltersState } from './types'
import { stateToAbbMap } from './usStates'

const PAGE_SIZE = 20

// keep params in ["type1","type2"] or [100, 200] format and remove non-string/non-number ([type1,"type2, type3"]) values
const queryParamsCheck = (
  queryValue: string | string[] | undefined,
  defaultValue: string[] | Range
) => {
  try {
    // Remove outer brackets and parse into array
    if (queryValue && typeof queryValue === 'string') {
      const filterValue = JSON.parse(
        `[${queryValue
          .substring(1, queryValue.length - 1)
          .split(',')
          .filter((item) => {
            const trimmed = item.trim() // Remove extra spaces
            return (
              Number.isInteger(Number(trimmed)) ||
              (trimmed[0] === '"' && trimmed.slice(-1) === '"')
            )
          })
          .join(',')}]`
      ) as typeof defaultValue
      return filterValue?.length > 0 ? filterValue : defaultValue
    } else {
      return defaultValue
    }
  } catch (error) {
    console.error('Error parsing queryValue:', queryValue, error)
    return defaultValue // Fallback to default value on error
  }
}

const composeAuctionStateFromQueryParams = (queryParams: {
  [key: string]: string | string[] | undefined
}): SearchState<AuctionFiltersState> => {
  const { q, p, s, o } = queryParams // q=query, p=page, s=sort, o=sortOrder

  return {
    query: typeof q === 'string' ? q : null,
    page: typeof p === 'string' ? parseInt(p) : 1,
    sortBy: validateSortBy(s as AuctionSearchSortBy),
    sortOrder: validateSortOrder(o),
    filters: reduce(
      defaultState.filters,
      (acc, defaultValue, filter) => {
        const queryValue = queryParams[filter]
        return {
          ...acc,
          [filter]: queryParamsCheck(queryValue, defaultValue),
        }
      },
      {}
    ) as AuctionFiltersState,
  }
}

type AuctionFilterParams = {
  category?: string[]
  condition?: string[]
  packagingCondition?: string[]
  manufacturer?: string[]
  shipmentType?: string[]
  country?: string[]
  usState?: string[]
  minWinningBidAmount?: number
  maxWinningBidAmount?: number
  minUnits?: number
  maxUnits?: number
  sellers?: string[]
  minRetailPrice?: number
  maxRetailPrice?: number
  [key: string]: string[] | string | number | null | undefined
}

const composeQueryFromAuctionState = ({
  page,
  query,
  sortBy,
  sortOrder,
  filters,
}: SearchState<AuctionFiltersState>): SearchParams['params'] => {
  const offset = (page - 1) * PAGE_SIZE

  const formattedFilters: AuctionFilterParams = {}
  Object.entries(filters).forEach((filter) => {
    const [key, value] = filter
    const castedKey = key as keyof AuctionFilterParams

    if (isStringList(value) && value.length > 0) {
      if (key === 'regions') {
        const usStates = value
          .filter((v) => v.includes('United States'))
          .map((v) => {
            const state = v.split('-')[1] as keyof typeof stateToAbbMap
            return stateToAbbMap[state]
          })

        if (usStates.length > 0) {
          ;(formattedFilters['usState'] as unknown) = usStates.join(',')
        }

        return
      } else {
        ;(formattedFilters[castedKey] as unknown) = value
        return
      }
    }

    if ((isDateRange(value) && value[0] && value[1]) || isRange(value)) {
      const filterKeyMap: Partial<{ [key: string]: [string, string] }> = {
        retailValueRanges: ['minRetailPrice', 'maxRetailPrice'],
        numberOfUnitsRanges: ['minUnits', 'maxUnits'],
        currentBidRanges: ['minWinningBidAmount', 'maxWinningBidAmount'],
      }
      const mappedKey = filterKeyMap[castedKey]
      if (mappedKey) {
        const [lowerKey, upperKey] = mappedKey
        if (value[0] != null) {
          formattedFilters[lowerKey] = Number(value[0])
        }
        if (value[1] != null) {
          formattedFilters[upperKey] = Number(value[1])
        }

        return
      }
    }
  })

  return {
    ...formattedFilters,
    query,
    limit: PAGE_SIZE,
    offset,
    sortBy,
    sortOrder,
  }
}

export type UrlParams = {
  [key: string]: string
}

const composeUrlParamsFromAuctionState = (
  state: SearchState<AuctionFiltersState>,
  excludeFilters?: string[]
): UrlParams => {
  const urlParams: UrlParams = {}

  if (state.page > 1) {
    urlParams.p = state.page.toString()
  }

  if (state.sortBy && state.sortBy !== 'endTime') {
    urlParams.s = state.sortBy
  }

  if (state.sortOrder !== AuctionSearchSortOrder.Ascending) {
    urlParams.o = state.sortOrder
  }

  if (state.query) {
    urlParams.q = state.query
  }

  Object.entries(state.filters).forEach((filter) => {
    const [key, value] = filter

    // Notable usage is storefront page where the seller filter
    // is implicit and shouldn't be in the query params.
    if ((excludeFilters ?? []).includes(key)) {
      return
    }

    if (
      (isStringList(value) && value.length > 0) ||
      (isDateRange(value) && value[0] && value[1]) ||
      (isRange(value) && (value[0] || value[1]))
    ) {
      urlParams[key] = JSON.stringify(value)
    }
  })

  return urlParams
}

export {
  composeAuctionStateFromQueryParams,
  composeQueryFromAuctionState,
  composeUrlParamsFromAuctionState,
  queryParamsCheck,
  PAGE_SIZE,
}
