import { useCallback, useRef, useState } from 'react'

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import type { TransitionStatus } from 'react-transition-group'
import { Transition } from 'react-transition-group'
import { styled, css } from 'styled-components'

import {
  Button,
  Input,
  CurrencyStringDollars,
  CurrencyStringCents,
  dollarsToCents,
} from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import type { LeftClosedRange, Range } from '@commonTypes'

import FilterRadioButton from './FilterRadioButton'

type RecordType = {
  key: string
  range: LeftClosedRange
}

type FormatProps = {
  lower: number | null
  upper: number | null
}

type NumericRangeFilterProps = {
  items: Range[]
  selected: Range
  onChange: (value: Range) => void
  formatRange?: React.FC<FormatProps>
  treatAsCents?: boolean
}

type FreeEntryInputProps = {
  value: number | ''
  placeholder?: string
  areaName: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  hasError?: boolean
}

const transitionStyles: {
  [key in TransitionStatus]: ReturnType<typeof css>
} = {
  entering: css`
    opacity: 0;
    transform: scaleY(0);
  `,
  entered: css`
    opacity: 1;
    transform: scaleY(1);
  `,
  exiting: css`
    opacity: 0;
    transform: scaleY(0);
  `,
  exited: css`
    opacity: 0;
    transform: scaleY(0);
  `,
  unmounted: css``,
}

const containerTransitionStyles: {
  [key in TransitionStatus]: ReturnType<typeof css>
} = {
  entering: css`
    padding-bottom: 0;
  `,
  entered: css`
    padding-bottom: 2.75rem;
  `,
  exiting: css`
    padding-bottom: 0;
  `,
  exited: css`
    padding-bottom: 0;
  `,
  unmounted: css``,
}

const IntegerFormat = ({ lower, upper }: FormatProps) =>
  upper ? (
    <>
      <FormattedNumber value={lower || 0} />
      {' - '}
      <FormattedNumber value={upper || 0} />
    </>
  ) : (
    <>
      <FormattedNumber value={lower || 0} />+
    </>
  )

const USDFormat = ({ lower, upper }: FormatProps) =>
  upper ? (
    <>
      <CurrencyStringDollars value={lower || 0} currency="USD" omitDecimal />
      {' - '}
      <CurrencyStringDollars value={upper || 0} currency="USD" omitDecimal />
    </>
  ) : (
    <>
      <CurrencyStringDollars value={lower || 0} currency="USD" omitDecimal />+
    </>
  )

const MSRPFormat = ({ lower, upper }: FormatProps) =>
  upper ? (
    <>
      <CurrencyStringCents value={lower || 0} currency="USD" omitDecimal />
      {' - '}
      <CurrencyStringCents value={upper || 0} currency="USD" omitDecimal />
    </>
  ) : (
    <>
      <CurrencyStringCents value={lower || 0} currency="USD" omitDecimal />+
    </>
  )

const Container = styled.div<{ $state: TransitionStatus }>`
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-gap: 0.625rem 0.5rem;

  grid-template-areas:
    'ranges ranges'
    'freeLower freeUpper'
    'error error'
    'btn btn';

  transition: all 150ms ease-in-out;

  ${(o) => containerTransitionStyles[o.$state]}
`

const List = styled.ol`
  grid-area: ranges;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 0.0625rem solid ${designColors.neutral.lightGray};
  font-size: 0.875rem;
`

const ListItem = styled.li`
  margin-bottom: 0.625rem;
`

const StyledErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 0.625rem;
  font-size: 0.8125rem;
  color: ${designColors.error.highlight};
`

const BaseFreeEntryInput = styled(Input)<{
  $areaName: FreeEntryInputProps['areaName']
}>`
  grid-area: ${(o) => o.$areaName};
  line-height: 1.5rem;
`

const Error = styled.div`
  grid-area: error;
  color: ${designColors.error.highlight};
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin: 0.25rem;
`

const FreeEntryInput = ({
  value,
  placeholder,
  areaName,
  onChange,
  hasError = false,
}: FreeEntryInputProps & { hasError?: boolean }) => (
  <>
    <BaseFreeEntryInput
      $areaName={areaName}
      type="number"
      min="0"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      error={hasError && areaName === 'freeUpper'} // Apply hasError prop only for 'freeUpper' area
    />
    {hasError && areaName === 'freeUpper' && (
      <Error>
        <StyledErrorIcon icon={faExclamationCircle} />
        <FormattedMessage id="BaseFreeEntryInput.hasError" />
      </Error>
    )}
  </>
)

const ShowResultsButton = styled(Button)<{ $state: TransitionStatus }>`
  position: absolute;
  width: 100%;

  grid-area: btn;
  transition:
    opacity 0.15s ease-out,
    transform 0.1s ease-out;
  transform-origin: bottom;
  ${(o) => transitionStyles[o.$state]}
`

export const emptyRange: Range = [null, null]

const isValueInItems = (items: Range[], value: Range) =>
  !!items.find((item: Range) =>
    isEqual(
      item.map((n) => +(n || 0)),
      value.map((n) => +(n || 0))
    )
  )

const NumericRangeFilter = ({
  items,
  selected,
  onChange,
  formatRange,
  treatAsCents = false,
}: NumericRangeFilterProps) => {
  const [freeEntryRange, setFreeEntryRange] = useState<Range>(emptyRange)
  const FormatComponent = formatRange || IntegerFormat
  const showResultBtnVisible = !!(freeEntryRange[0] || freeEntryRange[1])
  const invalidMax =
    freeEntryRange[1] !== null &&
    freeEntryRange[0] !== null &&
    freeEntryRange[1] < freeEntryRange[0]

  const setFreeLower = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10) || null
      if (value !== null && value < 0) {
        // Ignore negative values
        return
      }
      setFreeEntryRange([value, freeEntryRange[1]])
    },
    [freeEntryRange]
  )

  const setFreeUpper = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value) || null
      if (value !== null && value < 0) {
        // Ignore negative values
        return
      }
      setFreeEntryRange([freeEntryRange[0], value])
    },
    [freeEntryRange]
  )

  const handleShowResultsClick = () => {
    // Initialize convertedRange with default values
    let convertedRange: Range = [null, null]

    // Check the treatAsCents flag and convert the input values if needed
    if (treatAsCents) {
      const lowerValue =
        freeEntryRange[0] !== null ? dollarsToCents(freeEntryRange[0]) : null
      const upperValue =
        freeEntryRange[1] !== null ? dollarsToCents(freeEntryRange[1]) : null

      convertedRange = [lowerValue, upperValue]
    } else {
      // If not treating as cents, use the values as they are
      convertedRange = [freeEntryRange[0], freeEntryRange[1]]
    }

    onChange(convertedRange)

    if (isValueInItems(items, freeEntryRange)) {
      setFreeEntryRange(emptyRange)
    }
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const showResultsButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <Transition nodeRef={containerRef} in={showResultBtnVisible} timeout={150}>
      {(state) => (
        <Container $state={state} ref={containerRef}>
          <List>
            {items.map((range: Range, index: number) => {
              const checked = isValueInItems([selected], range)

              return (
                <ListItem key={index}>
                  <FilterRadioButton
                    checked={checked}
                    onChange={() => {
                      setFreeEntryRange(emptyRange)
                      onChange(range)
                    }}
                    label={
                      <FormatComponent lower={range[0]} upper={range[1]} />
                    }
                  />
                </ListItem>
              )
            })}
          </List>

          <FreeEntryInput
            areaName="freeLower"
            placeholder="Min"
            value={freeEntryRange[0] || ''}
            onChange={setFreeLower}
          />

          <FreeEntryInput
            areaName="freeUpper"
            placeholder="Max"
            value={freeEntryRange[1] || ''}
            onChange={setFreeUpper}
            hasError={invalidMax} // Pass the hasError prop based on the condition
          />

          <Transition
            nodeRef={showResultsButtonRef}
            in={showResultBtnVisible}
            timeout={150}
            unmountOnExit
          >
            {(state) => (
              <ShowResultsButton
                size="medium"
                ref={showResultsButtonRef}
                $state={state}
                onClick={handleShowResultsClick}
                disabled={invalidMax}
              >
                Show Results
              </ShowResultsButton>
            )}
          </Transition>
        </Container>
      )}
    </Transition>
  )
}

export default NumericRangeFilter

export { IntegerFormat, USDFormat, MSRPFormat }

export type { RecordType, Range, LeftClosedRange, FormatProps }
