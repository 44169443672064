import { useCallback } from 'react'

import { keepPreviousData, useQueryClient } from '@tanstack/react-query'

import type { QueryOptions } from '@b-stock/bstock-next'
import {
  makeOptionallyAuthenticatedQuery,
  useAccessToken,
} from '@b-stock/bstock-next'

import type { UpdateAuctionDataFunction } from '@components/features/auction/shared/useAuctionUpdates'

import applyAuctionUpdates from './applyAuctionUpdates'
import getSearchData from './getSearchData'
import {
  type SearchResultsPage,
  type SearchParams,
  isSearchResultWithAuction,
} from './types'

const augmentedListingSearchQuery = {
  ...makeOptionallyAuthenticatedQuery('search', getSearchData, {
    placeholderData: keepPreviousData,
  }),
  useWithUpdater: (
    params: SearchParams,
    opts?: QueryOptions<[SearchParams], SearchResultsPage>
  ) => {
    const results = augmentedListingSearchQuery.useWithOpts(
      [params],
      opts ?? {}
    )

    const accessToken = useAccessToken()
    const queryKey = augmentedListingSearchQuery.getKey(accessToken, [params])
    const queryKeyStr = JSON.stringify(queryKey)
    const queryClient = useQueryClient()
    const updateAuctionData = useCallback<UpdateAuctionDataFunction>(
      (update) => {
        const updatedResults = queryClient.setQueryData<SearchResultsPage>(
          queryKey,
          (oldData) => {
            if (oldData === undefined) {
              return undefined
            }

            return {
              ...oldData,
              listings: oldData.listings.map((oldListing) => {
                if (oldListing.auctionId !== update.auctionId) {
                  return oldListing
                }
                if (isSearchResultWithAuction(oldListing)) {
                  return applyAuctionUpdates(update, oldListing)
                }
                return oldListing
              }),
            }
          }
        )

        return updatedResults?.listings.find(
          (listing) => listing.auctionId === update.auctionId
        )
      },
      // We use queryKeyStr instead of queryKey because the specific 'params' object
      // will be a new object each time (because that's easier and generating new
      // objects isn't an issue anywhere else), so it would need to generate a new
      // callback every render. Since queryClient does its lookup via deep equality
      // for query keys, we're good re-generating this callback if (and only if)
      // the queryKeyStr changes.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [queryClient, queryKeyStr]
    )

    return {
      results,
      updateAuctionData,
    }
  },
}

export default augmentedListingSearchQuery
