import type * as React from 'react'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'
import { Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

export type RecordType<T> = {
  key: T
  name: React.ReactNode
}

type Props<T> = {
  label: string
  values: RecordType<T>[]
  onRemove: (key: T) => void
  noClose?: boolean
}

const Section = styled.div`
  margin-top: 0.75rem;
  margin-right: 0.75rem;
  min-width: max-content;
`

const SectionTitle = styled.div`
  margin-bottom: 0.25rem;
  ${Typography.Body3}
  color: ${designColors.neutral.darkGray};
`

const SectionValueContainer = styled.div`
  display: flex;
`

const SectionValue = styled.div`
  ${Typography.Body4}
  padding: 0.5rem 0.9375rem;
  margin-right: 0.25rem;
  min-width: fit-content;
  color: ${designColors.neutral.black};
  background: ${designColors.neutral.lightGray};
  border-radius: 1rem;
`

const RemoveBtn = styled(UnstyledButton)`
  border-left: 2px solid ${designColors.neutral.mediumGray};
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  svg {
    font-size: 0.75rem;
  }
`

const AppliedFilterSection = <T extends React.Key>({
  label,
  onRemove,
  values,
  noClose,
}: Props<T>) => {
  if (values.length === 0) return null
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id={label} />
      </SectionTitle>
      <SectionValueContainer>
        {values.map((item: RecordType<T>) => (
          <SectionValue key={item.key} onClick={() => onRemove(item.key)}>
            {item.name}
            {!noClose && (
              <RemoveBtn role="remove-btn">
                <FontAwesomeIcon icon={faTimes} />
              </RemoveBtn>
            )}
          </SectionValue>
        ))}
      </SectionValueContainer>
    </Section>
  )
}

export default AppliedFilterSection
