import type {
  BidListItem,
  LimitedAuctionListItem,
  Auction,
  LimitedAuction,
} from '@b-stock/auction-api-client'
import type {
  SearchListingsResponse,
  ListingSchema,
  ListingsSearchApiSearchListingsRequest,
  GetFiltersResponse,
} from '@b-stock/search-api-client'

export enum BidStatus {
  WINNING = 'WINNING',
  WON = 'WON',
  LOSING = 'LOSING',
  LOST = 'LOST',
  NO_BID = 'NO_BID',
}

export type SearchResult = ListingSchema & {
  bid?: BidListItem | null
  bidStatus: BidStatus
  auction?: Auction | LimitedAuction | LimitedAuctionListItem
}

export type SearchAuction = Auction | LimitedAuction | LimitedAuctionListItem

export type SearchResultWithAuction = Omit<SearchResult, 'auction'> & {
  auction: SearchAuction
}

export const isSearchResultWithAuction = (
  result: SearchResult
): result is SearchResultWithAuction => {
  return result.auction !== undefined
}

export type SearchResultsPage = Omit<SearchListingsResponse, 'listings'> & {
  listings: SearchResult[]
  allFilters: GetFiltersResponse
}

export type SearchParams = {
  params: Omit<ListingsSearchApiSearchListingsRequest, 'query'> & {
    query: string | null | undefined
  }
  fetchFilters?: boolean
  excludeListingId?: string
}
