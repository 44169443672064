import { styled } from 'styled-components'

import { Breakpoints } from '@b-stock/bstock-react/design-system'

export const SearchBarContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @media ${Breakpoints.max.medium} {
    padding: 0 0 1rem;
    justify-content: center;
  }
`
