/* This template is to be reused for pages involving my orders/bids/shipments/disputes/etc. */
import { useCallback, useRef, useState } from 'react'
import type * as React from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { styled } from 'styled-components'

import {
  Banner,
  Button,
  DropdownWithLabel,
  FormattedMessage,
} from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import type { AnyFilterState } from '@components/SearchProvider'
import { useIsMobile } from '@hooks/layout'

import FilterBar from './FilterBar'
import type { HeaderMenuLink } from './GenerateHeaderLinks'
import GenerateHeaderLinks from './GenerateHeaderLinks'
import MobileFilters from './MobileFilters'
import MobileSearch from './MobileSearch'
import { useSortOptions } from './sort'
import type { SortControls } from './sort/useSortOptions'
import type { FilterControls } from './types'
import UserPagination from './UserPagination'
import { SearchBar } from '../StandardLayout/Header/SearchBar'

const HeaderRow = styled.div`
  display: grid;
  align-items: center;
  overflow-x: auto;
  grid-template-areas: 'header-section header-subsection manage-menu';
  grid-template-columns: auto 1fr auto;
  grid-gap: 1.5rem;

  @media ${Breakpoints.max.small} {
    grid-gap: 0;
    grid-template-areas:
      'header-section'
      'header-subsection'
      'manage-menu';
    grid-template-columns: 1fr;
  }
`

const PageTitle = styled.h1`
  grid-area: header-section;
  ${Typography.Title1}
  margin: 2rem 0 3rem 0;

  @media ${Breakpoints.max.medium} {
    margin: 1rem 0 2rem 0;
  }

  @media ${Breakpoints.max.small} {
    margin: 0.5rem 0 1.5rem 0;
  }
`

const HeaderSubsection = styled.div`
  grid-area: header-subsection;
  flex-grow: 1;
`

const SecondaryBar = styled.div`
  display: grid;
  grid-template-areas: 'header-section header-subsection manage-menu';
  grid-template-columns: max-content 1fr auto;
  grid-gap: 0.75rem;
  align-items: baseline;
  overflow-x: auto;

  margin-top: 1.25rem;
  padding: 1rem 0 3rem 0;
  border-top: 0.0625rem solid ${designColors.neutral.mediumGray};

  @media ${Breakpoints.max.medium} {
    grid-gap: 0.5rem;
    grid-template-areas:
      'header-section'
      'header-subsection'
      'manage-menu';
    grid-template-columns: 1fr;
    padding-top: 1rem;
    margin-top: 0;
    padding-bottom: 2rem;
  }

  @media ${Breakpoints.max.small} {
    padding-bottom: 1.5rem;
  }
`

const TotalResults = styled.div`
  grid-area: header-section;
  ${Typography.Title3}
`

const SecondarySubsection = styled(HeaderSubsection)``

// TODO: This used to be `styled(FilterBartContainer)`. There's some styling we probably need from that
const MobileFilterSearchBar = styled.div`
  justify-content: flex-end;
`

const SearchFilterRow = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-gap: 16px;
  margin-top: 1rem;

  @media ${Breakpoints.max.medium} {
    grid-template-columns: 1fr;
    padding-bottom: 1.5rem;
  }
`

const StyledSearchBar = styled(SearchBar)`
  & {
    margin: 0;
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 3rem;
  @media ${Breakpoints.max.medium} {
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

type SearchControls = {
  setQuery: (query: string) => void
  placeholder: string
  initialValue?: string | null
}

type PaginationControls = {
  setPage: (page: number) => void
  currentPage: number
  perPage: number
  total: number
}

type UserPageTemplateProps<
  FiltersState,
  SearchResult,
  SortBy extends string,
  SortOrder extends string,
> = {
  renderPageTitle: React.ReactNode
  renderHeaderSubsection?: React.ReactNode
  headerMenu?: HeaderMenuLink[]
  renderSecondarySubsection?: React.ReactNode
  renderSectionAboveList?: React.ReactNode
  renderSectionAboveSearch?: React.ReactNode
  renderEmptyState?: React.ReactNode
  renderNoResultsState?: React.ReactNode
  filterControls?: FilterControls<FiltersState>
  sortControls?: SortControls<SortBy, SortOrder>
  searchControls?: SearchControls
  paginationControls: PaginationControls
  isLoading: boolean
  error: Error | null
  data: SearchResult[] | null
  CardComponent: (props: SearchResult) => React.ReactElement
  total?: number
  totalResults: React.ReactNode
  isResponsiveSearch?: boolean
}

const SortDropdown = <SortBy extends string, SortOrder extends string>({
  sortControls,
}: {
  sortControls: SortControls<SortBy, SortOrder>
}) => {
  const options = useSortOptions(sortControls)
  const handleDropdownChange = (value: string) => {
    const [sortBy, sortOrder] = value.split('&') as unknown as [
      SortBy,
      SortOrder,
    ]

    sortControls.setSort(sortBy, sortOrder)
  }

  return (
    <DropdownWithLabel
      label={<FormattedMessage id="Common.sortBy" />}
      value={`${sortControls.sortState.sortBy}&${sortControls.sortState.sortOrder}`}
      options={options}
      onChange={handleDropdownChange}
    />
  )
}

const UserPageTemplate = <
  FiltersState extends AnyFilterState,
  SearchResult,
  SortBy extends string,
  SortOrder extends string,
>({
  renderPageTitle,
  renderHeaderSubsection,
  headerMenu,
  renderSecondarySubsection,
  renderSectionAboveSearch,
  renderSectionAboveList,
  renderEmptyState,
  renderNoResultsState,
  filterControls,
  sortControls,
  searchControls,
  paginationControls,
  isLoading,
  error,
  data,
  total,
  totalResults,
  CardComponent,
  isResponsiveSearch,
}: UserPageTemplateProps<FiltersState, SearchResult, SortBy, SortOrder>) => {
  const [showMobileSearch, setShowMobileSearch] = useState(false)
  const isMobile = useIsMobile()

  const [showFilterModal, setShowFilterModal] = useState(false)
  const openFilterModal = useCallback(() => setShowFilterModal(true), [])
  const closeFilterModal = useCallback(() => setShowFilterModal(false), [])

  const isFilterStateDefault =
    !filterControls ||
    isEqual(filterControls.filterState, filterControls.defaultFilterState)
  const isSearchInitialValueEmpty =
    !searchControls || !searchControls.initialValue
  const isEmptyState =
    !!renderEmptyState &&
    total === 0 &&
    isFilterStateDefault &&
    isSearchInitialValueEmpty &&
    !isLoading

  const handleOnSearchSubmit = useCallback(
    (inputValue: string) => {
      if (!searchControls) {
        throw new Error('handleOnSearchSubmit used with no search controls')
      }
      searchControls.setQuery(inputValue)
    },
    [searchControls]
  )

  const secondaryBarRef = useRef<HTMLDivElement>(null)

  const openMobileSearch = () => {
    if (secondaryBarRef.current) {
      window.scrollTo({
        top: secondaryBarRef.current.offsetTop,
        behavior: 'smooth',
      })
      setShowMobileSearch(true)
    }
  }

  return (
    <>
      {isMobile && !isResponsiveSearch && searchControls ? (
        <MobileSearch
          show={showMobileSearch}
          hideSearch={() => setShowMobileSearch(false)}
          renderSearchBar={
            <StyledSearchBar
              onSubmit={(value) => {
                handleOnSearchSubmit(value)
                setShowMobileSearch(false)
              }}
              placeholder={searchControls.placeholder}
              initialValue={searchControls.initialValue}
            />
          }
        />
      ) : null}
      <HeaderRow>
        <PageTitle>{renderPageTitle}</PageTitle>
        <HeaderSubsection>{renderHeaderSubsection}</HeaderSubsection>

        {headerMenu ? <GenerateHeaderLinks headerMenu={headerMenu} /> : null}
      </HeaderRow>
      {renderSectionAboveSearch}

      {(!isMobile || isResponsiveSearch) &&
      (searchControls || filterControls) ? (
        <SearchFilterRow>
          {searchControls ? (
            <StyledSearchBar
              onSubmit={handleOnSearchSubmit}
              placeholder={searchControls.placeholder}
              initialValue={searchControls.initialValue}
            />
          ) : null}
          {filterControls ? (
            <FilterBar filterControls={filterControls} />
          ) : null}
        </SearchFilterRow>
      ) : null}

      {isEmptyState ? (
        renderEmptyState
      ) : (
        <>
          <SecondaryBar ref={secondaryBarRef}>
            <TotalResults data-testid="UserPageTemplate TotalResults">
              {totalResults}
            </TotalResults>
            <SecondarySubsection data-testid="UserPageTemplate SecondarySubsection">
              {renderSecondarySubsection}
            </SecondarySubsection>

            {isMobile &&
            !isResponsiveSearch &&
            (searchControls || filterControls) ? (
              <MobileFilterSearchBar>
                <Button onClick={openMobileSearch}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
                <Button onClick={openFilterModal}>
                  <FontAwesomeIcon icon={faSlidersH} />
                </Button>
              </MobileFilterSearchBar>
            ) : sortControls ? (
              <SortDropdown sortControls={sortControls} />
            ) : null}
          </SecondaryBar>
        </>
      )}

      {isMobile && showFilterModal && filterControls && (
        <MobileFilters
          onClose={closeFilterModal}
          onSubmit={closeFilterModal}
          closeModal={closeFilterModal}
          filterControls={filterControls}
          sortComp={
            sortControls ? (
              <SortDropdown sortControls={sortControls} />
            ) : undefined
          }
        />
      )}

      {renderSectionAboveList}

      {data?.length && !isLoading ? (
        <CardsContainer>
          {data.map((cardData: SearchResult, index: number) => (
            <CardComponent key={`card-component-${index}`} {...cardData} />
          ))}
        </CardsContainer>
      ) : null}
      {data &&
        !isLoading &&
        !isEmptyState &&
        total === 0 &&
        renderNoResultsState}
      {!isEmptyState && !isLoading && error && (
        <Banner type="error" title={error.name}>
          {error.message}
        </Banner>
      )}

      <UserPagination paginationControls={paginationControls} />
    </>
  )
}

export type { PaginationControls }
export default UserPageTemplate
